<template>
  <div>
    <CCard class="mob-pd-0px">
      <CCardHeader class="grey-header d-flex mob-flex-column ai-center">
        <CRow class="flex-flow-column">
          <CCol>
            <strong>{{ $t("diy_all_post.pageTitle") }}</strong>
          </CCol>
          <p class="desc" v-if="GET_ADMIN_SIGNIN_RES">
            {{ $t("diy_all_post.admindesc") }}
          </p>
          <p class="desc" v-else>{{ $t("diy_all_post.desc") }}</p>
        </CRow>
        <CButton
          @click="navigateToAddPost(null)"
          size="sm"
          color="info"
          class="ml-auto mob-w100"
          >{{ $t("diy_all_post.addPost") }}</CButton
        >
      </CCardHeader>

      <CCardBody class="user-manage-table pt-0px">
        <CRow>
          <CAlert v-if="alertValue" show color="danger">
            <div v-if="alertValue && alertValue.hasOwnProperty('valueList')">
              <p v-for="(value, index) in alertValue.valueList" :key="index">
                {{ value }}
              </p>
            </div>
            <div v-if="alertValue && alertValue.hasOwnProperty('codeList')">
              <span>{{ $t("cAlert.errorCode") + " : " }}</span
              ><span v-for="(code, index) in alertValue.codeList" :key="index">
                {{ code + " " }}
              </span>
            </div>
            <div v-if="alertValue && alertValue.constructor == String">
              <p>
                {{ alertValue }}
              </p>
            </div>
          </CAlert>
        </CRow>
        <CRow
          class="fullscreen-ex-row"
          :class="{
            table_count_visible:
              (userCount && userCount.total) ||
              (userCount && userCount.active) ||
              (userCount && (userCount.inActive || userCount.inActive == 0)),
          }"
        >
          <CCol v-if="isTable"
            id="user-manage-table"
            class="usersTable border-grey manage-user-table"
          >
            <cTable
              :fields="lsUserTableFields"
              :items="lsUserTableItems != null ? lsUserTableItems : []"
              caption="List Of Post"
              column-filter
              items-per-page-select
              @row-clicked="navigateToPostStatus"
              :items-per-page="manageUsersTableWatchList.itemsPerPage"
              hover
              :sorter="{ resetable: true }"
              :pagination="paginationSettings"
              @update:column-filter-value="saveFilterValue"
              :loading="isLoading"
              class="w-100 d-flex flex-flow-wrap"
              :sorterValue="{ column: 'name', asc: 'true' }"
              @filtered-items-change="getFilteredAgentCount"
              @page-change="onPageChange"
              @pagination-change="onPaginationChange"
              :tableWatchList="manageUsersTableWatchList"
              @getFields="onSaveColumns"
              :manageColumn="true"
              :paginationLenOption="true"
            >
              <template #mainimage="{ item }">
                <td>
                  <div class="px-2">
                    <cDiyThumbnail :imgURL="item.mainimage" />
                  </div>
                </td>
              </template>
              <!-- <template #status-filter>
                <div>
                  <cDropDown
                    :key="'status'"
                    v-model="status"
                    url="status"
                    :isAllowEmpty="false"
                    :ctrlCode="'value'"
                    :ctrlName="'name'"
                    :isShowCode="false"
                    :isMultiple="false"
                    :isSearchable="true"
                    :selValue="status"
                    v-on:input="onStatusChange"
                    class="hide-invalid-feedback"
                  />
                </div>
              </template> -->

              <template #status="{ item }">
                <td>
                  <CBadge
                    
                  >
                    {{ item.status }}
                  </CBadge>
                </td>
              </template>

              <template #no-items-view>
                <div class="text-center my-5">
                  <h2>{{ isLoading ? "" : $t("diy_all_post.listEmpty") }}</h2>
                </div>
              </template>

              <!-- <template #actions-filter>
                <td style="border-top: 0px; height: 100%">
                  <div @click="clearAllFilters()" class="clear-filter-btn">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      id="Capa_1"
                      x="0px"
                      y="0px"
                      viewBox="0 0 373.6 373.6"
                      style="
                        enable-background: new 0 0 373.6 373.6;
                        fill: #1e90ff;
                      "
                      xml:space="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M343.7,0H29.9c-5.5,0-10.1,4.9-10.1,10.5v64.2c0,0.3,0,0.6,0.1,1c0,0.1,0.1,0.3,0.1,0.4c0,0.2,0.1,0.3,0.1,0.5    c0,0.2,0.1,0.3,0.1,0.5c0,0.1,0.1,0.3,0.1,0.4c0.1,0.2,0.1,0.4,0.2,0.5c0,0.1,0.1,0.2,0.1,0.4c0.1,0.2,0.1,0.3,0.2,0.5    c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.2,0.2,0.3,0.2,0.4c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.1,0.2,0.3,0.3,0.4    c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.1,0.2,0.1,0.2,0.2l114.3,124.6v157.5c0,3.9,2.2,7.4,5.7,9.1c1.3,0.6,2.7,0.9,4.2,0.9    c2.4,0,4.7-0.8,6.5-2.4l80.2-68.3c2.2-1.9,3.5-4.7,3.4-7.6v-89.2L351,81.4c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.4    c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.2,0.2-0.3,0.3-0.4s0.2-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.1-0.2,0.2-0.4    c0.1-0.2,0.2-0.3,0.2-0.5c0-0.1,0.1-0.2,0.1-0.4c0.1-0.2,0.1-0.3,0.2-0.5c0-0.1,0.1-0.3,0.1-0.4c0-0.2,0.1-0.3,0.1-0.5    s0.1-0.3,0.1-0.5c0-0.1,0.1-0.3,0.1-0.4c0-0.3,0.1-0.6,0.1-1V10.4C353.8,4.9,349.3,0,343.7,0z M219.5,195.6    c-1.7,1.8-2.7,4.1-2.7,6.6v88.4l-60,51.3V202.2c0-2.5-1-4.8-2.7-6.6L52.6,85H321L219.5,195.6z M333.8,65h-294V20h294V65z"
                          />
                        </g>
                      </g>
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                    </svg>
                    {{ $t("diy_all_post.clearFilter") }}
                  </div>
                </td>
              </template> -->

              <template #actions="{ item }">
                <td class="py-2 d-flex ai-center">
                  <div class="row">
                    <CCol>
                      <div
                        @click.stop="navigateToPostStatus(item)"
                        class="px-2 edit-icon icon-25px c-pointer"
                        color="info removewrap"
                        v-c-tooltip.hover="{
                          content: $t('allHotels.edit'),
                          active: false,
                        }"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="512pt"
                          viewBox="0 0 512 512"
                          width="512pt"
                          style="fill: #3c4b64"
                        >
                          <path
                            d="m368 511.957031h-309.332031c-32.363281 0-58.667969-26.304687-58.667969-58.667969v-309.332031c0-32.363281 26.304688-58.667969 58.667969-58.667969h181.332031c8.832031 0 16 7.167969 16 16 0 8.832032-7.167969 16-16 16h-181.332031c-14.699219 0-26.667969 11.96875-26.667969 26.667969v309.332031c0 14.699219 11.96875 26.667969 26.667969 26.667969h309.332031c14.699219 0 26.667969-11.96875 26.667969-26.667969v-181.332031c0-8.832031 7.167969-16 16-16s16 7.148438 16 16v181.332031c0 32.363282-26.304688 58.667969-58.667969 58.667969zm0 0"
                          />
                          <path
                            d="m187.136719 340.820312c-4.203125 0-8.300781-1.664062-11.308594-4.691406-3.796875-3.777344-5.417969-9.21875-4.371094-14.445312l15.082031-75.433594c.617188-3.113281 2.152344-5.953125 4.371094-8.171875l220.953125-220.925781c22.867188-22.871094 60.074219-22.871094 82.964844 0 11.070313 11.070312 17.171875 25.792968 17.171875 41.472656s-6.101562 30.398438-17.195312 41.472656l-220.925782 220.949219c-2.21875 2.238281-5.078125 3.753906-8.171875 4.371094l-75.414062 15.082031c-1.046875.214844-2.113281.320312-3.15625.320312zm75.433593-31.082031h.214844zm-45.609374-52.457031-9.410157 47.144531 47.125-9.429687 217.515625-217.511719c5.035156-5.058594 7.808594-11.734375 7.808594-18.859375s-2.773438-13.804688-7.808594-18.859375c-10.367187-10.390625-27.285156-10.390625-37.714844 0zm0 0"
                          />
                          <path
                            d="m453.332031 134.976562c-4.09375 0-8.191406-1.558593-11.304687-4.695312l-60.332032-60.351562c-6.25-6.25-6.25-16.382813 0-22.632813s16.382813-6.25 22.636719 0l60.328125 60.351563c6.25 6.25 6.25 16.382812 0 22.632812-3.136718 3.117188-7.230468 4.695312-11.328125 4.695312zm0 0"
                          />
                        </svg>
                      </div>
                    </CCol>

                    <CCol>
                      <div
                        @click.stop="onConfirmDeletePost(item)"
                        color=" danger"
                        v-c-tooltip.hover="{
                          content: $t('allHotels.delete'),
                          active: false,
                        }"
                        class="icon-25px c-pointer"
                      >
                        <CIcon
                          style="color: #cd0606; font-size: 22px"
                          name="cil-trash"
                          size="lg"
                        ></CIcon>
                      </div>
                    </CCol>
                  </div>
                </td>
              </template>
            </cTable>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
     <cAlertModal
      v-if="showAlertModal"
      :buttonList="buttonList"
      :status="modalStatus"
      :show="showAlertModal"
      :desc="confirmText"
    ></cAlertModal>
  </div>
</template>

<script>
import cDropDown from "./../../components/form/cDropDown";
import { navigate } from "../../router/index";
import cAlertModal from "./../../components/form/cAlertModal.vue";
import { mapGetters, mapActions } from "vuex";
import { goBack, updateDataEdited } from "@/router/index";
import {
  PATHNAME,
  COMPONENT_NAME,
  DEFAULT_DROPDOWN_STATUS,
  ORDER,
  ALERT_MODAL_STATUS,
} from "./../../util/constants";
import ApiDIYGetAllDiyPost from "./../../services/diy/apiGetAllDiyPosts";
import Logger from "./../../services/logger";
import SERVICE_URL, { FILE_NAME, LOG_TYPE } from "./../../config/API_DATA";

import {
  getErrorMessage,
  VALIDATE_GL_SUCCESS_RES,
} from "../../util/apiValidate";
import {
  handleJWT,
  getErrorAlertValue,
  addClassToBody,
  removeClassBody,
  OrderByList,
} from "../../util/util";
import BaseConfiguration from "../../config/base.js";
import cTable from "./../../components/dataTable/cTable";
import cDiyThumbnail from "./../../components/diy/cDiyThumbnail";
import ApiDeletePostDetails from '../../services/diy/apiDeletePost';
import Vue from "vue";
export default {
  name: COMPONENT_NAME.DIY_ALL_POST,
  components: {
    cDropDown,
    cTable,
    cDiyThumbnail,
    cAlertModal
  },

  data: () => {
    return {
      isTable: true,
      userCount: null,
      manageUsersTableWatchList: {
        filteredlistCount: null,
        itemsPerPage: 25,
        currentPageNum: 1,
      },
      rolesServiceURL: SERVICE_URL.GET_USER_ROLES,
      showAlertModal: false,

      user: {
        emailid: "",
        name: "",
      },
      status: {
        name: DEFAULT_DROPDOWN_STATUS.ALL,
        value: null,
      },

      role: {
        roleName: null,
      },
      paginationSettings: {
        align: "end",
      },
      alertValue: null,

      lsUsers: [],
      lsUserTableItems: [],
      lsUserTableFields: [],
      isLoading: true,
      alertValue: null,
      showAlertModal: false,
      modalStatus: null,
      buttonList: [],
      confirmText: null,
    };
  },

  methods: {
    ...mapActions("sAddUser", ["SET_SELECTED_USER"]),
    ...mapActions("sManageUsers", {
      SET_TAGS_LIST: "SET_TAGS_LIST",
      SET_USER_COUNT: "SET_USER_COUNT",
      CLEAR_MANAGE_USERS_STORE: "CLEAR_MANAGE_USERS_STORE",
    }),
    ...mapActions("sManageDiyPost", {
      SET_ALL_POST_RESPONSE: "SET_ALL_POST_RESPONSE",
      CLEAR_MANAGE_USERS_STORE: "CLEAR_MANAGE_USERS_STORE",
    }),
    ...mapActions("sAddDiyPost", {
      SET_SELECTED_POST: "SET_SELECTED_POST",
    }),

    ...mapActions("sMain", {
      ON_PROGRESS_START: "ON_PROGRESS_START",
      ON_PROGRESS_FINISH: "ON_PROGRESS_FINISH",
      ON_PROGRESS_FAIL: "ON_PROGRESS_FAIL",
      ON_PROGRESS_SET: "ON_PROGRESS_SET",
    }),
    onSaveColumns(pFields) {
      try {
        if (BaseConfiguration.isDebug) console.log("onSaveColumns");
        this.lsUserTableFields = pFields;
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.MANAGE_USERS,
          "error in onSaveColumns",
          err.toString(),
          "onSaveColumns",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
    onPaginationChange(pItemsPerPage) {
      try {
        if (BaseConfiguration.isDebug) console.log("onPaginationChange");
        this.manageUsersTableWatchList.itemsPerPage = pItemsPerPage;
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.MANAGE_USERS,
          "error in onPaginationChange",
          err.toString(),
          "onPaginationChange",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    onPageChange(pPageNum) {
      try {
        if (BaseConfiguration.isDebug) console.log("onPageChange");
        this.manageUsersTableWatchList.currentPageNum = pPageNum;
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.MANAGE_USERS,
          "error in onPageChange",
          err.toString(),
          "onPageChange",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    getFilteredAgentCount(pFilteredItems) {
      try {
        if (BaseConfiguration.isDebug) console.log("getFilteredAgentCount");
        this.manageUsersTableWatchList.filteredlistCount =
          pFilteredItems.length;
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.MANAGE_USERS,
          "error in getFilteredAgentCount",
          err.toString(),
          "getFilteredAgentCount",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
    clearAllFilters() {
      try {
        if (BaseConfiguration.isDebug) console.log("clearAllFilters");

        this.role = { roleName: DEFAULT_DROPDOWN_STATUS.ALL };
        this.status = {
          name: DEFAULT_DROPDOWN_STATUS.ALL,
          value: null,
        };

        if (this.user) {
          Object.keys(this.user).forEach((key) => {
            this.user[key] = "";
          });
        }
        this.lsUserTableItems = JSON.parse(JSON.stringify(this.lsUsers));
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.MANAGE_USERS,
          "error in clearAllFilters",
          err.toString(),
          "clearAllFilters",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    saveFilterValue(pUserObj) {
      try {
        if (BaseConfiguration.isDebug) console.log("saveFilterValue");
        if (pUserObj) {
          this.user = pUserObj;
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.MANAGE_USERS,
          "error in saveFilterValue",
          err.toString(),
          "saveFilterValue",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
    getUserFields() {
      let returnValue = [];
      try {
        if (BaseConfiguration.isDebug) console.log("getUserFields");

        let lsFields = [
          {
            key: "mainimage",
            // _style: "width:30%",
            label: this.$t("diy_all_post.postImage"),
            isLocked: true,
            sorter: false,
            filter: false,
          },
          {
            key: "title",
            // _style: "width:30%",

            label: this.$t("diy_all_post.title"),
            isLocked: true,
            filter: false,
          },
          {
            key: "category",
            // _style: "width:30%",

            label: this.$t("diy_all_post.category"),
            isLocked: true,
            filter: false,
          },
          {
            key: "tag",
            // _style: "width:30px;",
            label: this.$t("diy_all_post.tag"),
            isLocked: false,
            filter: false,
          },
          {
            key: "author",
            // _style: "width:30px;",
            label: this.$t("diy_all_post.author"),
            isLocked: false,
            filter: false,
          },

          {
            key: "publishDate",
            label: this.$t("diy_all_post.publishDate"),
            // _style: "width:30px",
            sorter: false,
            isLocked: true,
            filter: false,
          },

          {
            key: "status",
            label: this.$t("diy_all_post.status"),
            // _style: "width:20px",
            sorter: false,
            filter: false,
            isLocked: true,
          },

          {
            key: "actions",
            label: this.$t("diy_all_post.actions"),
            // _style: "width:20px",

            sorter: false,
            filter: false,
            isLocked: true,
          },
        ];
        returnValue = lsFields;
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.MANAGE_USERS,
          "error in getUserFields",
          err.toString(),
          "getUserFields",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return returnValue;
    },

    showAlert(value) {
      this.alertValue = value;
    },
    onStatusChange(pStatus) {
      try {
        if (BaseConfiguration.isDebug) console.log("onStatusChange");

        this.lsUserTableItems = JSON.parse(JSON.stringify(this.lsUsers));
        if (
          this.lsUserTableItems != null &&
          this.lsUserTableItems.length > 0 &&
          pStatus
        ) {
          let role = this.role;
          this.lsUserTableItems = this.lsUserTableItems.filter(function (user) {
            if (
              user.status == pStatus.name &&
              (user.roles == role.roleName ||
                role.roleName == DEFAULT_DROPDOWN_STATUS.ALL)
            ) {
              return true;
            } else if (
              pStatus.name == DEFAULT_DROPDOWN_STATUS.ALL &&
              user.roles == role.roleName
            ) {
              return true;
            } else if (
              pStatus.name == DEFAULT_DROPDOWN_STATUS.ALL &&
              role.roleName == DEFAULT_DROPDOWN_STATUS.ALL
            ) {
              return true;
            } else {
              return false;
            }
          });
        }

        // this.resetUserRole();
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.MANAGE_USERS,
          "error in on Status Change",
          err.toString(),
          "onStatusChange",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
    setSelectedPostObj(pSelectedUserObj) {
      try {
        if (BaseConfiguration.isDebug) console.log("setSelectedPostObj");
        // console.log(this.allPostResponse);
        // console.log(pSelectedUserObj);
        if (
          pSelectedUserObj != null &&
          this.allPostResponse &&
          this.allPostResponse.length
        ) {
          pSelectedUserObj = this.allPostResponse.find(function (user) {
            return user.id == pSelectedUserObj.id;
          });
          pSelectedUserObj = JSON.parse(JSON.stringify(pSelectedUserObj));
          this.SET_SELECTED_POST(pSelectedUserObj);
        } else {
          this.SET_SELECTED_POST(null);
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.MANAGE_USERS,
          "error in selecting User obj",
          err.toString(),
          "setSelectedUserObj",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    navigateToAddPost(pSelectedUserObj) {
      try {
        if (BaseConfiguration.isDebug) console.log("navigateToAddPost");

        this.setSelectedPostObj(pSelectedUserObj);
        navigate(PATHNAME.ADD_DIY_POST);
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.MANAGE_USERS,
          "error in navigate To User",
          err.toString(),
          "navigateToUser",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
    navigateToPostStatus(pSelectedUserObj) {
      try {
        if (BaseConfiguration.isDebug) console.log("navigateToPostStatus");

        this.setSelectedPostObj(pSelectedUserObj);

        navigate(PATHNAME.ADD_DIY_POST);
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.MANAGE_USERS,
          "error in navigate To User Status",
          err.toString(),
          "navigateToPostStatus",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
    async getAllDiyPost() {
      let returnValue;
      try {
        if (BaseConfiguration.isDebug) console.log("getAllDiyPost");

        let getAllDiyPostRequest = ApiDIYGetAllDiyPost.buildRequest();

        if (getAllDiyPostRequest != null) {
          let allPostResponse = await ApiDIYGetAllDiyPost.getResponse(
            getAllDiyPostRequest
          );
          if (VALIDATE_GL_SUCCESS_RES(allPostResponse)) {
            returnValue = allPostResponse.data.diy_project_project;
            if (allPostResponse.data.diy_project_project) {
              this.userCount = allPostResponse.data.diy_project_project.count;
              this.SET_USER_COUNT(this.userCount);
            }
            this.showAlert(null);
            this.SET_ALL_POST_RESPONSE(returnValue);
          } else {
            let error = getErrorMessage(allPostResponse);

            let isJwt = handleJWT(error);
            if (!isJwt) {
              this.showAlert(getErrorAlertValue(error));
            }
          }
        } else {
          throw "invalid request";
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ALL_POST,
          "error in  get All Users",
          err.toString(),
          "getAllDiyPost",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return returnValue;
    },

    getUsersTableItems(pAllUserResponse) {
      let retValue;
      try {
        if (BaseConfiguration.isDebug) console.log("getUsersTableItems");

        if (pAllUserResponse && pAllUserResponse.length > 0) {
          pAllUserResponse.forEach((value) => {
            this.lsUsers.push({
              mainimage: value.mainimage,
              title: value.title,
              category: value.category.categoryname,
              tag: value.field_of_work,
              author: value.author,
              publishDate: new Date(),
              id: value.id,
              status: value.status==1 ? "Published" : 
                      value.status==2 ? "Drafted" :
                       value.status==3 ? "Pending":
                       value.status==0 ? 'Unpublished': 'Rejected',
            });
          });
        } else {
          this.lsUsers = [];
        }
        retValue = JSON.parse(JSON.stringify(this.lsUsers));
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.MANAGE_USERS,
          "error in  getUsersTableItems",
          err.toString(),
          "getUsersTableItems",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }

      return retValue;
    },
    getPropToSortUsers(pUser) {
      try {
        if (BaseConfiguration.isDebug) console.log("getPropToSortUsers");
        if (pUser.firstname) {
          return pUser.firstname.toLowerCase();
        }
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.MANAGE_USERS,
          "error in getPropToSortUsers",
          err.toString(),
          "getPropToSortUsers",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

     navigateToAllUsers() {
      try {
        goBack();
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in  navigate To All Users",
          err.toString(),
          "navigate To All Users"
        );
        Logger.getResponse(body);
      }
    },

    onConfirmDeletePost(item) {
      try {
        this.showModal(ALERT_MODAL_STATUS.INFO,item)
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in  onConfirmDeletePost",
          err.toString(),
          "onConfirmDeletePost"
        );
        Logger.getResponse(body);
      }
    },
       async onDeleteSelectedPost(pPost) {
      try {
         
          let deletePostStatus = await this.onDeletePost(pPost);
          if (deletePostStatus) 
          {
            this.showModal(ALERT_MODAL_STATUS.SUCCESS);
            
          }
       
        updateDataEdited(false);
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in  onCreateUserFormSubmit",
          err.toString(),
          "onCreateUserFormSubmit",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    async onDeletePost(pPost) {
      let returnValue;

      try {
        this.showAlertModal = false;
      
        let deletPostRequest = ApiDeletePostDetails.buildRequest(
          pPost.id
        );
        
        if (deletPostRequest) {
          let deletPostResponse = await ApiDeletePostDetails.getResponse(
            deletPostRequest
          );
          if (VALIDATE_GL_SUCCESS_RES(deletPostResponse)) {
                //succ
                returnValue = deletPostResponse.data;
                this.showAlert(null);
               
              } else {
                //err
                let error = getErrorMessage(deletPostResponse);
                let isJwt = handleJWT(error);
                if (!isJwt) {
                  this.showAlert(getErrorAlertValue(error));
                }
          }
        } else {
          throw "invalid request";
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in onDeletePost",
          err.toString(),
          "onDeletePost",
          LOG_TYPE.ERROR
        );

        Logger.getResponse(body);
      }

      return returnValue;
    },

    toggleAlertModal() {
      this.showAlertModal = !this.showAlertModal;
    },
     showModal(pStatus,pPost) {
      try {
        // let retValue;
        this.showAlertModal = true;
        if (pStatus) {
          this.modalStatus = pStatus;
        } 
        let desc = null;
        if (this.modalStatus == ALERT_MODAL_STATUS.INFO) {
          this.buttonList = [
            {
              name: this.$t("addDiyPost.no"),
              func: this.toggleAlertModal,
              color: "danger",
            },
            {
              name: this.$t("addDiyPost.yes"),
              func: this.onDeleteSelectedPost,
              color: "success",
              param: pPost
            },
          ];
          desc = 'Are you sure you want to delete this post' + pPost.title + '?';
        } else if (this.modalStatus == ALERT_MODAL_STATUS.SUCCESS) {
          this.buttonList = [
            {
              name: this.$t("addDiyPost.ok"),
              func: this.getAllPostLs,
              color: "danger",
            },
          ];
          desc = 'Deleted Successfully!'
        }
        this.confirmText = desc;
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in showModal",
          err.toString(),
          "showModal",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      // return retValue;
    },
 sortList(tableLs,propName, order){
   if(tableLs && tableLs.length!=0) {
    tableLs.sort((a, b) => {
          if (a[propName] < b[propName])
              return -1;
          if (a[propName] > b[propName])
              return 1;
          return 0;
      });
      if (order === "DESC") {
        tableLs.reverse();
    } 
   }
    return tableLs;
  },

  async getAllPostLs(){
     let listUsers = [];
      // if (this.allPostResponse != null && this.allPostResponse.length > 0) {
      //   listUsers = this.allPostResponse;
      // } else {
      listUsers = await this.getAllDiyPost();
      // }
      listUsers = this.sortList(listUsers,'id','DESC')

      var tagsLs = [];
      listUsers.filter(post => {
        if(post && post.tags && post.tags.length!=0){
          post.tags.filter(tag => {
            if(tag){
              tagsLs.push(tag)
            }
          })
          
        }
      })
      if(tagsLs && tagsLs.length!=0){
        
       tagsLs = Array.from(new Set(tagsLs));
       this.SET_TAGS_LIST(tagsLs)
      }else{
       this.SET_TAGS_LIST([])
      }
      listUsers = OrderByList(listUsers, this.getPropToSortUsers, ORDER.ASC);

      if (listUsers)
        this.lsUserTableItems = this.getUsersTableItems(
          JSON.parse(JSON.stringify(listUsers))
        );

      this.lsUserTableFields = this.getUserFields();
      if(this.showAlertModal){
        this.showAlertModal=false;
        this.isTable = false;
          let that = this;

          Vue.nextTick(function() {
            that.isTable = true;
          });
      }
      if (this.GET_POST_COUNT) {
        this.userCount = this.GET_POST_COUNT;
      }
      this.isLoading = false;
  }
  },

  async created() {
    try {
      if (BaseConfiguration.isDebug) console.log("created");

     this.getAllPostLs();
    } catch (err) {
      let body = Logger.buildRequest(
        FILE_NAME.MANAGE_USERS,
        "error in  Created",
        err.toString(),
        "created",
        LOG_TYPE.ERROR
      );
      Logger.getResponse(body);
    }
  },
  computed: {
    ...mapGetters("sManageDiyPost", {
      allPostResponse: "GET_ALL_POST_RESPONSE",
      GET_POST_COUNT: "GET_POST_COUNT",
    }),
    ...mapGetters("sSysLogin", {
      GET_ADMIN_SIGNIN_REQ: "GET_ADMIN_SIGNIN_REQ",
      GET_ADMIN_SIGNIN_RES: "GET_ADMIN_SIGNIN_RES",
    }),
  },
  beforeCreate() {
    addClassToBody(COMPONENT_NAME.MANAGE_USERS);
  },

  beforeDestroy() {
    removeClassBody();
  },
};
</script>
