import SERVICE_URL from "../../config/API_DATA"
import Logger from '../logger.js';
import {
    FILE_NAME,
    LOG_TYPE
} from "../../config/API_DATA";
import Apollo from "../../graphql/ApolloClient";
import gql from "graphql-tag";

export default class ApiDIYGetAllDiyPost {
    static buildRequest() {
        let returnValue = null;
        try {
            let query = gql `query 
            { diy_project_project 
                { 
                    id  
                    description
                    duration
                    field_of_work
                    mainimage 
                    title 
                    isvideo
                    link
                    author
                    required_items
                    timeinmins
                    status
                    categoryid
                    groupid  
                    tags
                    category { 
                        categoryname 
                    }
                    project_items {
                        item_name
                        itemid
                        project_item_links {
                          countryid
                          ecom_link
                          ecom_source
                          itemid
                          linkid
                        }
                        qty
                        unit
                      }
                    
                 } }`;
            returnValue = query;
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_GET_ALL_DIY_POST, "error in building body for GetAllUser api", err.toString(), "buildRequest", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }

    static async getResponse(pReqOptions) {


        let returnValue = null;
        try {
            returnValue = await Apollo.makeApolloQueryRequest(SERVICE_URL.GET_ALL_DIY_POST, pReqOptions);
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_GET_ALL_DIY_POST, "error in getting Response from GetAllUser api ", err.toString(), "getResponse", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }
}