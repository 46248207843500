import SERVICE_URL from "../../config/API_DATA"
import Logger from '../logger.js';
import {
    FILE_NAME,
    LOG_TYPE
} from "../../config/API_DATA";
import Apollo from "../../graphql/ApolloClient";
import gql from "graphql-tag";

export default class ApiDeletePostDetails {
    static buildRequest(id) {
        let returnValue = null;
        try {
            if (!id) throw new Error('Error value not found')
            let query = gql `mutation delete_diy_project {
                delete_diy_project_project(where: {id: {_eq: ${id}}})
                {
                  returning{
                    id
                  }
                }
              }
              `;
            returnValue = query;
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_DELETE_DIY_POST, "error in building query for DeleteDiyPostDetails api", err.toString(), "buildRequest", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }

    static async getResponse(pReqOptions) {
        let returnValue = null;
        try {
            returnValue = await Apollo.makeApolloMutationRequest(SERVICE_URL.GET_ALL_DIY_POST, pReqOptions);
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_DELETE_DIY_POST, "error in getting Response from DeleteDiyPostDetails api ", err.toString(), "getResponse", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }
}