<template>
  <div class="">
      <img width="60" height="60" :src="imgURL?imgURL:imgSrc"/>
  <!-- <img v-if="imgURL && isValidImage" width="60" height="60"  @error="ImgError"
  :src="imgSrc"/>
   <img v-if="!isValidImage || !imgURL" width="60" height="60" src="@imgPath/img/png/defaultImg.png" /> -->
  </div>
</template>

<script>
import BaseConfiguration from '../../config/base';
import { COMPONENT_NAME } from '../../util/constants';
export default {
    name:COMPONENT_NAME.C_DIY_THUMBNAIL,
    props:{

        imgURL:{
            type:String,
            default:null
        }


    },
    data:()=>{
        return{
           imgSrc:null,
           isValidImage: true,
        }
    },
    methods:{
        ImgError(){
            this.isValidImage = false;
            this.imgSrc = '@imgPath/img/png/defaultImg.png'
        }
    },
    created(){
        if(this.imgURL){
            this.imgSrc = this.imgURL
            this.isValidImage = true;
        }
        else {
            this.isValidImage = false;
        }
        
    }
}
</script>

<style>

</style>